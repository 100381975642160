
import React, { useState, useContext, useEffect } from "react";
import {
  Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane,
  NavItem,
  NavLink,
  CardFooter, CardHeader,UncontrolledAlert
} from "reactstrap";

import { useParams, Link, useNavigate } from 'react-router-dom';
import { TotalPositionsCard, TotalCalledCard, TotalCallableCard, DistributedCard, NAVCard, GainLossesCard, DPICard, TVPICard, IRRCard, } from "./dashboard_lib.js";

import UseMoondanceApi from "../lib/api";

import MoondanceTable from "../lib/components/MoondanceTable.js";

import { buildTableData, buildAccountParameter } from "../lib/formatData";
import { MoondanceContext } from '../../App';
import Yearly from "../pages/yearly";
import Budget from "../pages/budget";

import Positions      from '../pages/positions_list';
import Summary        from "../pages/summary.js";
import Transactions   from "../pages/transactions_list";
import Notes          from "../pages/notes_list";

import MoondanceLayout from "../lib/layout.js";
import DefaultChart from "../lib/charts/defaultChart.js";
import WaterFallChart from "../lib/charts/WaterFallChart";
import BarChart from "../lib/charts/barchart";
import AlertPortrait from "../lib/components/AlertPortrait.js";
import Loading from "../lib/components/Loading.js";

const MoonDanceDashboard = () => {
  document.title = "Dashboard | Moondance";

  const params = useParams();
  const moondanceApi = UseMoondanceApi();
  const { darkMode } = useContext(MoondanceContext);
  const { portraitMode } = useContext(MoondanceContext);
  const { lastUpdate } = useContext(MoondanceContext);
  const [overviewChartCurrency,setOverviewChartCurrency]=useState('user_currency'); 
  const [ai_reply, setAiReply] = useState('');
  const { selectedAccountList } = useContext(MoondanceContext);
  const { selectedStatus } = useContext(MoondanceContext);

  const { userCurrency } = useContext(MoondanceContext);
  const [DATA, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [cashflowChartDisplay, setCashflowChartDisplay] = useState('All');
  const [showCapitalCallsOnly, setShowCapitalCallsOnly] = useState(false)
  const [activeTab, setactiveTab] = useState("summary");
  const [showBudget, setShowBudget] = useState(false)
  const [min_year, set_min_year] = React.useState(0);
  const [max_year, set_max_year] = React.useState(0);

  const navigate = useNavigate();
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  function changeChartDisplay(newValue) {
    setCashflowChartDisplay(newValue);

  }
  function changeCapitalCallOnly(newValue) {
    setShowCapitalCallsOnly(newValue);


  }

  async function analyse_ai() {
    
    setAiReply(<><span className="spinner-border text-primary" style={{ height: '1em', width: '1em', marginRight: '1em' }} role="status"></span> Waiting for AI to analyse this page ...</>);
    
    let ai_data ={}
    ai_data.usercurrency = userCurrency;
    ai_data.summary = DATA.summary;
    ai_data.perYear = DATA.perYear;
    ai_data.expectedBalanceInUserCurrency = DATA.expectedBalanceInUserCurrency;
    ai_data.listPositions = DATA.listPositions;
    ai_data.positionCashFlowPerYear = DATA.positionCashFlowPerYear;
    

    
    try {
      // Convert the data to JSON format
      console.log('DATA', DATA);
      let new_ai_reply = await moondanceApi.ai_analyse({
        data: JSON.stringify(DATA.summary)
      });
      console.log('ai_reply', new_ai_reply);

      // Ensure new_ai_reply is not undefined or null before checking its length
      if (new_ai_reply && new_ai_reply.data && new_ai_reply.data.content) {

        
        setAiReply(new_ai_reply.data.content);
      } else {
        setAiReply('No data returned from AI');
      }
    } catch (error) {
      setAiReply('AI is not responding');
      console.log(error);
    }


  }
  useEffect(() => {
    if (params.id) { setactiveTab(params.id) }
  }, [params, params.id]);

  useEffect(() => {



    async function fetchYearlyData(api_parameters) {
      try {

        if (api_parameters.account && api_parameters.status) {
          const return_data = await moondanceApi.fetchYearlyTransactionPerPosition(api_parameters);
          const years = return_data.data.map(item => Number(item.year));
          
          // Find the minimum and maximum year values
          let min_year = Math.min(...years)
          let max_year = Math.max(...years)
          set_min_year(min_year);
          set_max_year(min_year);
          let positionFlow = {};

          positionFlow['meta_data'] = []
          positionFlow['meta_data']['min_year'] = min_year
          positionFlow['meta_data']['max_year'] = max_year

          return_data.data.forEach((item) => {
            if (!positionFlow[item.position_id]) {

              positionFlow[item.position_id] = {
                id: item.position_id,
                name: item.name,
                balance_in_user_currency: {},
                cash_in_in_user_currency: {},
                cash_out_in_user_currency: {},
                balance_in_commitment_pct: {},
                cash_in_in_commitment_pct: {},
                cash_out_in_commitment_pct: {},
              }

              for (let i = min_year; i <= max_year; i++) {
                positionFlow[item.position_id].balance_in_user_currency[i] = 0;
                positionFlow[item.position_id].cash_in_in_user_currency[i] = 0;
                positionFlow[item.position_id].cash_out_in_user_currency[i] = 0;
                positionFlow[item.position_id].balance_in_commitment_pct[i] = 0;
                positionFlow[item.position_id].cash_in_in_commitment_pct[i] = 0;
                positionFlow[item.position_id].cash_out_in_commitment_pct[i] = 0;
              }

            }
            if (item.year !== undefined && item.year !== null) {
              positionFlow[item.position_id].balance_in_user_currency[item.year] = item.balance_in_user_currency;
              positionFlow[item.position_id].cash_in_in_user_currency[item.year] = item.cash_in_in_user_currency;
              positionFlow[item.position_id].cash_out_in_user_currency[item.year] = item.cash_out_in_user_currency;
              positionFlow[item.position_id].balance_in_commitment_pct[item.year] = item.balance_in_commitment_pct;
              positionFlow[item.position_id].cash_in_in_commitment_pct[item.year] = item.cash_in_in_commitment_pct;
              positionFlow[item.position_id].cash_out_in_commitment_pct[item.year] = item.cash_out_in_commitment_pct;
            }
          });
          return positionFlow
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchData() {
      var api_parameters = {
        account: buildAccountParameter(selectedAccountList),
        status: buildAccountParameter(selectedStatus),
        userCurrency: userCurrency,
      };
      if (api_parameters.account && api_parameters.status) {
        try {
          var returnData = {};

          // Fetch summary, yearly, and positions data in parallel
          const [summarydata, yearlyData, positionsData, positionCashFlowPerYear, positionExpectedCashFlowPerYear] = await Promise.all([
            moondanceApi.fetchDashboardSummary(api_parameters),
            moondanceApi.fetchYearly(api_parameters),
            moondanceApi.fetchPositionsList({
              account: api_parameters.account,
              status: api_parameters.status,
              calculateIrr: true,
              keyword:''
            }),
            fetchYearlyData(api_parameters),

            moondanceApi.fetchPositionExpectedCashFlowPerYear(api_parameters)
          ]);

          //sort result of fetchPositionExpectedCashFlowPerYear  to group them per position_id and year
          let model_balance_value_in_user_currency = {}
          let model_balance_in_pct_of_commitment = {}
          let model_call_value_in_user_currency = {}
          let model_distribution_value_in_user_currency = {}



          if (positionExpectedCashFlowPerYear.data) {
            //console.log('positionExpectedCashFlowPerYear', positionExpectedCashFlowPerYear)
            positionExpectedCashFlowPerYear.data.forEach((item, index) => {

              if (!model_balance_in_pct_of_commitment[item.position_id]) {
                model_balance_in_pct_of_commitment[item.position_id] = {}
              }
              if (!model_balance_value_in_user_currency[item.position_id]) {
                model_balance_value_in_user_currency[item.position_id] = {}
              }
              if (!model_call_value_in_user_currency[item.position_id]) {
                model_call_value_in_user_currency[item.position_id] = {}
              }
              if (!model_distribution_value_in_user_currency[item.position_id]) {
                model_distribution_value_in_user_currency[item.position_id] = {}
              }
              model_balance_in_pct_of_commitment[item.position_id][item.year] = item.model_balance_in_pct_of_commitment
              model_balance_value_in_user_currency[item.position_id][item.year] = item.model_balance_value_in_user_currency
              model_call_value_in_user_currency[item.position_id][item.year] = item.model_call_value_in_user_currency
              model_distribution_value_in_user_currency[item.position_id][item.year] = item.model_distribution_value_in_user_currency

            })
          }

          returnData.expectedBalanceInPctOfCommitment = model_balance_in_pct_of_commitment
          returnData.expectedBalanceInUserCurrency = model_balance_value_in_user_currency
          returnData.expectedCallsInUserCurrency = model_call_value_in_user_currency
          returnData.expectedDistributionInUserCurrency = model_distribution_value_in_user_currency
          
          



          if (yearlyData.data.length > 0) {
            returnData.perYear = MoondanceLayout.filterDataPeryear(yearlyData.data);
            //console.log('perYear', returnData.perYear)
            returnData.listPositions = positionsData.data;
            returnData.ai_position_comments = positionsData.ai;
            
            returnData.positions_metadata = positionsData.metadata;
            returnData.table = buildTableData(yearlyData, positionsData.data);
            returnData.summary = summarydata.data;
            returnData.overviewChart = MoondanceLayout.buildOverviewChart(returnData,false);
            returnData.callable = MoondanceLayout.buildCallableChart(returnData);
            returnData.cashflow = [];
            returnData.cashflow['Past'] = MoondanceLayout.buildCashFlowChart(returnData, 'Past', false, showBudget);
            returnData.cashflow['Future'] = MoondanceLayout.buildCashFlowChart(returnData, 'Future', false, showBudget);
            //console.log('overviewChartCurrency',overviewChartCurrency)
            // Await the result of fetchYearlyData()

            // Calculate stats based on cashflow
            returnData.stats = {};
            returnData.stats.sumOfFutureCalls = 0;
            returnData.stats.sumOfFutureDistributions = 0;
            returnData.stats.futureAddedGain = 0;
            returnData.stats.sumOfPastCalls = 0;
            returnData.stats.sumOfPastDistributions = 0;
            returnData.stats.pastAddedGain = 0;

            returnData.stats.sumOfCalls = 0;
            returnData.stats.sumOfDistributions = 0;
            returnData.stats.addedGain = 0;

            if (returnData.cashflow['Future'] && returnData.cashflow['Future'].data[0] && returnData.cashflow['Future'].data[0].data) {
              returnData.stats.sumOfFutureCalls = returnData.cashflow['Future'].data[0].data.reduce((a, b) => a + b, 0);
            }
            if (returnData.cashflow['Future'] && returnData.cashflow['Future'].data[1] && returnData.cashflow['Future'].data[1].data) {
              returnData.stats.sumOfFutureDistributions = returnData.cashflow['Future'].data[1].data.reduce((a, b) => a + b, 0);
            }
            returnData.stats.sumOfFutureCashFlows = returnData.stats.sumOfFutureCalls + returnData.stats.sumOfFutureDistributions;

            if (returnData.cashflow['Past'] && returnData.cashflow['Past'].data[0] && returnData.cashflow['Past'].data[0].data) {
              returnData.stats.sumOfPastCalls = returnData.cashflow['Past'].data[0].data.reduce((a, b) => a + b, 0);
            }
            if (returnData.cashflow['Past'] && returnData.cashflow['Past'].data[1] && returnData.cashflow['Past'].data[1].data) {
              returnData.stats.sumOfPastDistributions = returnData.cashflow['Past'].data[1].data.reduce((a, b) => a + b, 0);
            }
            returnData.stats.sumOfPastCashFlows = returnData.stats.sumOfPastCalls + returnData.stats.sumOfPastDistributions;

            returnData.stats.sumOfCalls = returnData.stats.sumOfPastCalls + returnData.stats.sumOfFutureCalls;
            returnData.stats.sumOfDistributions = returnData.stats.sumOfPastDistributions + returnData.stats.sumOfFutureDistributions;
            returnData.stats.sumOfCashFlows = returnData.stats.sumOfCalls + returnData.stats.sumOfDistributions;

            returnData.cashflow['All'] = MoondanceLayout.buildCashFlowChart(returnData, 'All', false, showBudget);

            returnData.cumulatedCashflow = [];
            returnData.cumulatedCashflow['Past'] = MoondanceLayout.buildCumulatedCashFlowChart(returnData, 'Past', false, showBudget);
            returnData.cumulatedCashflow['Future'] = MoondanceLayout.buildCumulatedCashFlowChart(returnData, 'Future', false, showBudget);

            returnData.cumulatedCashflow['Future_callOnly'] = MoondanceLayout.buildCumulatedCashFlowChart(returnData, 'Future', true, showBudget);
            returnData.cumulatedCashflow['All'] = MoondanceLayout.buildCumulatedCashFlowChart(returnData, 'All', false, showBudget);
            returnData.positionCashFlowPerYear = positionCashFlowPerYear;
            setData(returnData);
          } else {
            setData('nodata');
          }
          //console.log('fetchData', returnData)
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      }
    }

    if (selectedAccountList && selectedStatus && cashflowChartDisplay) {
      setLoading(true);
      fetchData().then(() => {
        setLoading(false);

      });
    }
  }, [selectedAccountList, selectedStatus, showBudget, lastUpdate]);



  return (
    <React.Fragment >
      <div className={(darkMode ? 'page-content darkMode' : 'page-content')} >
        <Container fluid={true}>
          <Row style={{ marginBottom: '1em' }}>



          </Row>
          {(loading ?
            <Loading/>
            :
            (!DATA.summary || DATA.summary.total_count_positions === 0 ?
              <Row>
                <Col xl={8}  lg ={3} sm={12} md={6} xs={12}>
                  <Card  >
                    <CardHeader className="bg-transparent border-bottom  card-header ">
                      <h5 style={{ marginTop: "1em" }}>No data is available for display.</h5>
                    </CardHeader>
                    <CardBody>
                      <p>This may be because no result match your research criteria </p>
                      <p>or because you haven't created any position in your account yet.</p>
                    </CardBody>
                    <CardFooter className="bg-transparent border-bottom  card-footer flex flex-row-reverse">
                      <a href="/addPosition">
                        <button className="btn btn-primary">Add a new position</button>
                      </a>
                    </CardFooter>
                  </Card>
                </Col>
                <Col xl={4}  lg ={3} sm={12} md={12} xs={12} >
                  <p ><b>Selected Account :</b>{MoondanceLayout.displayArrayLabels(selectedAccountList)}<br />
                    <b>Selected Status :</b>{MoondanceLayout.displayArrayLabels(selectedStatus)}<br />
                    <b>Currency :</b>{userCurrency}
                  </p>
                </Col>
              </Row>
              :
              <>
                <Row >
                  {(portraitMode ?
                <Col xl={3} lg={3} sm={4} md={3} xs={12}  style={{marginBottom:'1em',marginTop:'0em'}}>
                    <Card style={{ marginBottom: '0em' }} >
                      <CardBody>
                        <p style={{ fontSize: '0.8em', marginBottom: '0em' }}>
                          <Link className="success" to={'/selectAccount'}>Selected Account</Link> :{MoondanceLayout.displayArrayLabels(selectedAccountList)}<br />
                          <Link className="success" to={'/selectAccount'}>Selected Status :</Link> :{MoondanceLayout.displayArrayLabels(selectedStatus)}</p>
                      </CardBody>
                    </Card>

                  </Col>
                :'')}

                  <Col xl={9}  lg={9} sm={8} md={9} xs={12}>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={(activeTab === 'summary' ? "active nav-link " : "")}
                          onClick={() => {
                            navigate('/dashboard/summary')
                          }}
                        >
                          <i className="mdi mdi-desktop-mac-dashboard"> </i>{" "}
                          KPIs
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={(activeTab === 'positions' ? "active nav-link " : "")}
                          onClick={() => {

                            navigate('/dashboard/positions')
                          }}
                        >
                          <i className="fas fa-chess"></i>{" "}
                          Positions
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={(activeTab === 'cashflows' ? "active nav-link " : "")}
                          onClick={() => {
                            navigate('/dashboard/cashflows')
                          }}
                        >
                          <i className="fas fa-money-check-alt"></i>{" "}Cash Flows
                        </NavLink>

                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}

                          className={(activeTab === 'transactions' ? "active nav-link " : "")}
                          onClick={() => {

                            navigate('/dashboard/transactions')
                          }}
                        >
                          <i className="mdi mdi-cash-multiple"></i>{" "}
                          Transactions
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={(activeTab === 'budget' ? "active nav-link " : "")}
                          onClick={() => {
                            navigate('/dashboard/budget')
                          }}
                        >
                          <i className=" fas fa-piggy-bank"></i>{" "}Budget
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={(activeTab === 'charts' ? "active nav-link " : "")}
                          onClick={() => {
                            navigate('/dashboard/charts')
                          }}
                        >
                          <i className="mdi mdi-chart-areaspline"></i>{" "}Charts
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={(activeTab === 'table' ? "active nav-link " : "")}
                          onClick={() => {
                            navigate('/dashboard/table')
                          }}
                        >
                          <i className="mdi mdi-table"></i>{" "}Table
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={(activeTab === 'notes' ? "active nav-link " : "")}
                          onClick={() => {
                            navigate('/dashboard/notes')
                          }}
                        >
                          <i className=" bx bx-notepad"></i>{" "}Notes
                        </NavLink>
                      </NavItem>

                    </Nav>
                  </Col>
 
                  {(!portraitMode ?
                  <Col xl={2} sm={4} md={3} xs={12}>
                    <Card style={{ marginBottom: '0em' }} >
                      <CardBody>
                        <p style={{ fontSize: '0.8em', marginBottom: '0em' }}>
                          <Link className="success" to={'/selectAccount'}>Selected Account</Link> :{MoondanceLayout.displayArrayLabels(selectedAccountList)}<br />
                          <Link className="success" to={'/selectAccount'}>Selected Status :</Link> :{MoondanceLayout.displayArrayLabels(selectedStatus)}</p>
                      </CardBody>
                    </Card>

                  </Col>:'')}
                </Row>
                <TabContent activeTab={activeTab} className="p-3">
                  <TabPane tabId="summary" id="Summary">
             
                    {(activeTab === "summary" ? <>
                    <Row className="flex " style={{marginBottom:'1em'}}>
                   
                      <Col  xl={10} sm={12}  className="d-flex"><h2 className="hideOnMobile">Key performance Indicators :</h2></Col>
                    </Row>
                      {ai_reply ? <UncontrolledAlert color="info"><h5>Asking AI : </h5><p style={{ whiteSpace: 'pre-line' }} >{ai_reply}</p></UncontrolledAlert> : ''}

                      <Row>
                        <Col xl={7} sm={12} md={12}>
                          <Row>

                            <IRRCard data={DATA} xlWidth={4} smWidth={4}  mdWidth={4} xsWidth={12} />
                            <DPICard data={DATA} xlWidth={4} smWidth={4} mdWidth={4} xsWidth={6} />
                            <TVPICard data={DATA} xlWidth={4} smWidth={4}  mdWidth={4}xsWidth={6} />
                          </Row>
                          <Row>
                            <TotalPositionsCard data={DATA} xlWidth={4} mdWidth={4} smWidth={4} />
                            <TotalCalledCard data={DATA} xlWidth={4} mdWidth={4} smWidth={4} />
                           
                            <NAVCard data={DATA} xlWidth={4} mdWidth={4} smWidth={4} />
                          </Row>
                          <Row>
                          <TotalCallableCard data={DATA} xlWidth={4} mdWidth={4} smWidth={4} xsWidth={12} />

                          <GainLossesCard data={DATA} xlWidth={4} mdWidth={4} smWidth={4} xsWidth={12} />

                            <DistributedCard data={DATA} xlWidth={4} mdWidth={4} smWidth={4} />
                          </Row>

                          <Row>

                            <Col xl={12} sm={12} md={12}>
                              <Yearly />
                            </Col>
                          </Row>
                        </Col>

                        <Col xl={5} sm={12}>
                          <Card >
                            <CardBody>
                              <CardTitle className="mb-4">Overview</CardTitle>
                              {(loading ?  <Loading/>:
                                (DATA.overviewChart.data && DATA.callable.data.length > 0 ?

                                  <WaterFallChart

                                    title="Overview"
                                    data={DATA.overviewChart.data}
                                    options={DATA.overviewChart.options}
                                    horizontal={true}
                                    height={585}
                                    currency={(userCurrency ? userCurrency : 'EUR')}
                                  /> : <p>No data</p>)
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <div>
                        <p className="text-muted mb-0">* Capital calls, distributions, and other transactions in foreign currencies are converted at their respective historic rates. The NAV (Net Asset Value) of positions in foreign currencies is converted at the most recent exchange rate available. </p>
                        <p className="text-muted "> **  Expected cash flows are based on a user-specified model for each position.</p>
                      </div>
                    </> : <></>)}
                  </TabPane>

                  <TabPane tabId="cashflows" id="cashflows">
                  {(
                      (activeTab === "cashflows" && portraitMode) ?
                      <Card >
                      <CardBody>
                        <span className=" fas fa-redo"></span> Rotate your device to display more data
                        </CardBody>
                        </Card>
                      :'')}
                    {(
                      (activeTab === "cashflows" && !portraitMode) ?
                      <Row>

                        <h2>Cashflows :</h2>
                        <Yearly />
                        <Col xl={12} sm={12}>
                          <Card >
                          
                            <CardBody>
                              <CardTitle className="mb-4E d-flex justify-content-between align-items-center">

                                {cashflowChartDisplay === 'Future' && 'Expected Cashflow'}
                                {cashflowChartDisplay === 'Past' && 'Actual Cashflow'}
                                {cashflowChartDisplay === 'All' && <p>Actual and Expected Cashflow<br /><small>Current year mix actual and expected cashflows</small></p>}

                                <div
                                  className="btn-group"
                                  role="group"
                                >


                                  <button
                                    type="button"
                                    className={cashflowChartDisplay === 'Past' ? 'btn btn-primary btn-sm' : 'btn btn-outline-primary btn-sm'}

                                    onClick={() => { setShowCapitalCallsOnly(false); changeChartDisplay('Past') }} >Past</button>
                                  <button
                                    type="button"
                                    className={cashflowChartDisplay === 'Future' ? 'btn btn-primary btn-sm' : 'btn btn-outline-primary btn-sm'}
                                    onClick={() => { setShowCapitalCallsOnly(false); changeChartDisplay('Future') }}>Future</button>

                                  <button
                                    type="button"
                                    className={cashflowChartDisplay === 'All' ? 'btn btn-primary btn-sm' : 'btn btn-outline-primary btn-sm'}
                                    onClick={() => { setShowCapitalCallsOnly(false); changeChartDisplay('All') }}>All</button>

                                  {cashflowChartDisplay !== 'Past' && DATA.budget && <button
                                    type="button"
                                    style={{ marginLeft: '1em' }}
                                    className={showBudget ? 'btn btn-primary btn-sm' : 'btn btn-outline-primary btn-sm'}
                                    onClick={() => { if (!showBudget) { setShowCapitalCallsOnly(false) }; setShowBudget(!showBudget) }}>Show Budget</button>
                                  }
                                </div>

                              </CardTitle>
                              {(loading ?   <Loading/> :
                                (cashflowChartDisplay && DATA.cashflow[cashflowChartDisplay] ?

                                  <BarChart
                                    key={`cashflow-${cashflowChartDisplay}`}
                                    chartKey={`cashflow-${cashflowChartDisplay}`}
                                    title="Expected Cashflow"
                                    data={DATA.cashflow[cashflowChartDisplay].data}
                                    options={DATA.cashflow[cashflowChartDisplay].options}
                                    stacked={true}
                                    currency={(overviewChartCurrency ==='user_currency'  ?  userCurrency : (DATA.summary.asset_currency ? DATA.summary.asset_currency : 'EUR'))}  

                                  /> : <p>No data</p>)
                              )}
                              <Row>
                                <Col xl={12} sm={12}>
                                  <table className="table table-condensed">
                                    <thead>
                                      <tr style={{ textAlign: 'right' }}>
                                        <th></th>
                                        <th>Capital Calls</th>
                                        <th>Distribution</th>
                                        <th>Cash Flow</th>
                                        <th>NAV</th>
                                        <th>TVPI</th>


                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr style={{ textAlign: 'right' }}>
                                        <td>Actual (Past)</td>
                                        <td >{MoondanceLayout.formatCurrency(DATA.stats.sumOfPastCalls, userCurrency)} <span style={{ width: '4em', backgroundColor: MoondanceLayout.colorSchemedanger }}>&nbsp;&nbsp;&nbsp;</span></td>
                                        <td>{MoondanceLayout.formatCurrency(DATA.stats.sumOfPastDistributions, userCurrency)} <span style={{ width: '4em', backgroundColor: MoondanceLayout.colorSchemesuccess }}>&nbsp;&nbsp;&nbsp;</span></td>
                                        <td> {MoondanceLayout.formatCurrency(DATA.stats.sumOfPastCashFlows, userCurrency)}</td>
                                        <td> {MoondanceLayout.formatCurrency(DATA.summary.total_nav_in_user_currency, userCurrency)}</td>
                                        <td> {MoondanceLayout.formatNumber((DATA.summary.total_nav_in_user_currency + DATA.stats.sumOfPastDistributions) / -DATA.stats.sumOfPastCalls, 2)}x</td>

                                      </tr>
                                      <tr style={{ textAlign: 'right' }}>
                                        <td>Expected (Future)</td>
                                        <td>{MoondanceLayout.formatCurrency(DATA.stats.sumOfFutureCalls, userCurrency)} <span style={{ width: '4em', backgroundColor: MoondanceLayout.colorSchemeexpected_neutral }}>&nbsp;&nbsp;&nbsp;</span></td>
                                        <td>{MoondanceLayout.formatCurrency(DATA.stats.sumOfFutureDistributions, userCurrency)} <span style={{ width: '4em', backgroundColor: MoondanceLayout.colorSchemeexpected_neutral }}>&nbsp;&nbsp;&nbsp;</span></td>
                                        <td> {MoondanceLayout.formatCurrency(DATA.stats.sumOfFutureCashFlows, userCurrency)}</td>
                                        <td><i>Fully distributed</i></td>
                                        <td> {MoondanceLayout.formatNumber((DATA.stats.sumOfFutureDistributions) / (DATA.summary.total_nav_in_user_currency - DATA.stats.sumOfFutureCalls), 2)}x</td>
                                      </tr>


                                      <tr style={{ textAlign: 'right' }}>
                                        <td>All</td>
                                        <td>{MoondanceLayout.formatCurrency(DATA.stats.sumOfCalls, userCurrency)}<span style={{ width: '4em', }}>&nbsp;&nbsp;&nbsp;</span></td>
                                        <td>{MoondanceLayout.formatCurrency(DATA.stats.sumOfDistributions, userCurrency)}<span style={{ width: '4em', }}>&nbsp;&nbsp;&nbsp;</span></td>
                                        <td>{MoondanceLayout.formatCurrency(DATA.stats.sumOfCashFlows, userCurrency)}</td>
                                        <td><i>Fully distributed</i></td>
                                        <td>{MoondanceLayout.formatNumber((DATA.stats.sumOfDistributions) / -DATA.stats.sumOfCalls, 2)}x</td>
                                      </tr>

                                    </tbody>
                                  </table>

                                </Col>

                              </Row>
                              
                            </CardBody>
                            
                          </Card>
                        </Col>
                        {(cashflowChartDisplay !== 'Past' ?
                          <Col xl={12} sm={12}>
                            <Card >
                              <CardBody>
                                <CardTitle>Future Cashflows</CardTitle>
                                <Row>

                                  <p>
                                    <b><span className="mdi mdi-alert-outline text-danger"></span> Please be aware that future cashflows are based on hypothetical models.</b><br />
                                    The expected cashflow is derived from assumptions provided during the creation or modification of each asset.<br />
                                    Capital calls and distributions can be manually adjusted by editing the assets. Future distributions are forecasted using actual capital calls and the hypotheses outlined above, or they can be manually set during the asset's modification.
                                  </p>
                                </Row>

                              </CardBody>
                            </Card>
                          </Col> : '')}

                        <Col xl={12} sm={12}>
                          <Card >
                            <CardBody>
                              <CardTitle className="mb-4E d-flex justify-content-between align-items-center">


                                {cashflowChartDisplay === 'Future' && 'Expected Cumulated Cashflow'}
                                {cashflowChartDisplay === 'Past' && 'Actual Cumulated Cashflow '}
                                {cashflowChartDisplay === 'All' && <p>Actual and Expected Cumulated Cashflow <br /><small>Current year mix actual and expected cashflows</small></p>}

                                <div
                                  className="btn-group"
                                  role="group"
                                  aria-label="Basic mixed styles example"
                                >
                                  <button
                                    type="button"
                                    className={cashflowChartDisplay === 'Past' ? 'btn btn-primary btn-sm' : 'btn btn-outline-primary btn-sm'}

                                    onClick={() => { setShowCapitalCallsOnly(false); changeChartDisplay('Past') }} >Past</button>
                                  <button
                                    type="button"
                                    className={cashflowChartDisplay === 'Future' ? 'btn btn-primary btn-sm' : 'btn btn-outline-primary btn-sm'}
                                    onClick={() => { setShowCapitalCallsOnly(false); changeChartDisplay('Future') }}>Future</button>

                                  <button
                                    type="button"
                                    className={cashflowChartDisplay === 'All' ? 'btn btn-primary btn-sm' : 'btn btn-outline-primary btn-sm'}
                                    onClick={() => { setShowCapitalCallsOnly(false); changeChartDisplay('All'); }}>All</button>

                                  {cashflowChartDisplay !== 'Past' && DATA.budget && <button
                                    type="button"
                                    style={{ marginLeft: '1em' }}
                                    className={showBudget ? 'btn btn-primary btn-sm' : 'btn btn-outline-primary btn-sm'}
                                    onClick={() => { if (!showBudget) { setShowCapitalCallsOnly(false) }; setShowBudget(!showBudget) }}>Show Budget</button>
                                  }

                                  {cashflowChartDisplay === 'Future' && <button
                                    type="button"
                                    style={{ marginLeft: '1em' }}
                                    className={showCapitalCallsOnly ? 'btn btn-primary btn-sm' : 'btn btn-outline-primary btn-sm'}
                                    onClick={() => { changeCapitalCallOnly(!showCapitalCallsOnly); setShowBudget(false); }}>Show Calls only</button>
                                  }
                                </div>
                              </CardTitle>
                              {(loading ?   <Loading/> :
                                (cashflowChartDisplay && DATA.cumulatedCashflow[cashflowChartDisplay] ?
                                  <BarChart
                                    key={`cumulated-${cashflowChartDisplay}`}
                                   

                                    chartKey={`cumulated-${cashflowChartDisplay}`}
                                    title="Expected Cashflow"
                                    data={(showCapitalCallsOnly ? DATA.cumulatedCashflow['Future_callOnly'].data : DATA.cumulatedCashflow[cashflowChartDisplay].data)}
                                    options={(showCapitalCallsOnly ? DATA.cumulatedCashflow['Future_callOnly'].options : DATA.cumulatedCashflow[cashflowChartDisplay].options)}
                                    stacked={true}
                                    currency={(userCurrency ? userCurrency : 'EUR')}
                                  /> : <p>No data</p>)
                              )}
                              <Row>
                                <Col xl={4} sm={4}>
                                  <p><span style={{ width: '4em', backgroundColor: MoondanceLayout.colorScheme.dark }}>&nbsp;&nbsp;&nbsp;</span> Actual incremental cash flow</p>
                                </Col>
                                <Col xl={4} sm={4}>
                                  <p><span style={{ width: '4em', backgroundColor: MoondanceLayout.colorScheme.expected_neutral }}>&nbsp;&nbsp;&nbsp;</span> Expected incremental cash flow</p>
                                </Col>
                                <Col xl={4} sm={4}>
                                  <p><span style={{ width: '4em', backgroundColor: MoondanceLayout.colorScheme.success }}>&nbsp;&nbsp;&nbsp;</span> Cumulative Cashflow (Actual + Expected)</p>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>

                        </Col>

                        <Col xl={12} sm={12}>
                          <Card>
                            <CardBody>

                              {(DATA.perYear && DATA.perYear.length > 0 ? (
                                <MoondanceTable
                                  rows={DATA.perYear}
                                  defaultSort={{ key: 'year', direction: 'ascending' }}
                                  paginationSize={25}
                                  columns={[
                                    { label: 'Year', field: 'year', style: { borderRight: '1px dashed black' } },
                                    { label: 'New Position', field: 'count_new_positions', format: 'number', align: 'right', showTotal: true },
                                    { label: 'New Commitment', field: 'sum_new_commitment_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: true, style: { borderRight: '1px dashed black' } },
                                    { 
                                      label: 'Beginning of year NAV', 
                                      field: 'sum_position_boy_nav_in_user_currency', 
                                      customFormat :(row)=>{return MoondanceLayout.formatCurrency(row.sum_position_boy_nav_in_user_currency, userCurrency)},
                                      format: 'currency', 
                                      formatUnit: userCurrency, 
                                      align: 'right',
                                      showTotal: false, 
                                    },
                                    { label: 'Actual Capital call', field: 'sum_real_capital_call_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: true },
                                    { label: 'Actual Distribution', field: 'sum_real_distribution_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: true },
                                    { label: 'Actual Cash Flow', field: 'sum_real_balance_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: true,  },
                                    { 
                                      label: 'End of year NAV', 
                                      field: 'sum_position_eoy_nav_in_user_currency', 
                                      customFormat :(row)=>{return MoondanceLayout.formatCurrency(row.sum_position_eoy_nav_in_user_currency, userCurrency)},
                                      format: 'currency', 
                                      formatUnit: userCurrency, 
                                      align: 'right',
                                      showTotal: false, 
                                      style: { borderRight: '1px dashed black' }
                                    },
                                    
                                    { 
                                      label: 'Value creation', 
                                      field: 'sum_position_nav_in_user_currency', 
                                      customFormat :(row)=>{
                                          return (
                                                MoondanceLayout.formatCurrency(
                                                  (   
                                                    row.sum_position_eoy_nav_in_user_currency 
                                                    - row.sum_position_boy_nav_in_user_currency 
                                                    + row.sum_real_balance_in_user_currency
                                                  ), userCurrency) 
                                               
                                                )
                                      },
                                      format: 'currency', 
                                      formatUnit: userCurrency, 
                                      align: 'right', 
                                      style: { borderRight: '1px dashed black' } 
                                    },
                                    { label: 'Actual Cumulated Cash Flow', field: 'cumul_actual_balance_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: false, },
                                   

                                    { label: 'Expected Remaining Capital Call', field: 'sum_remaining_capital_call_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: true, },
                                    { label: 'Expected Remaining Distribution', field: 'sum_remaining_distribution_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: true },
                                    { label: 'Expected Remaining Cash Flow', field: 'sum_remaining_balance_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: true, style: { borderRight: '1px dashed black' } },


                                    { label: 'Total Cash Flow', field: 'balance_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: true },
                                    { label: 'Total Cumulated Cash Flow', field: 'cumul_balance_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: false, style: { borderRight: '1px dashed black' } },



                                  ]}


                                />
                              ) : < p>No Position found</p>
                              )}


                            </CardBody>
                          </Card>
                        </Col>
                        <div>
                          <p className="text-muted mb-0">* Capital calls, distributions, and other transactions in foreign currencies are converted at their respective historic rates. The NAV (Net Asset Value) of positions in foreign currencies is converted at the most recent exchange rate available. </p>
                          <p className="text-muted "> **  Expected cash flows are based on a user-specified model for each position.</p>
                        </div>
                      </Row>
                      : '')}
                  </TabPane>
                  <TabPane tabId="positions" id="positions">
                    {(activeTab === "positions" ?
                      <Row>

                        <h2>Positions :</h2>
                        {(
                          loading ?
                          <Loading/> :
                            (
                              DATA.listPositions.length > 0 ?


                                <Positions
                                  data={DATA}
                                  metadata={DATA.positions_metadata}
                                /> :
                                ' No Positions '
                            )
                        )}
                        <div>
                          <p className="text-muted mb-0">* Capital calls, distributions, and other transactions in foreign currencies are converted at their respective historic rates. The NAV (Net Asset Value) of positions in foreign currencies is converted at the most recent exchange rate available. </p>
                          <p className="text-muted "> **  Expected cash flows are based on a user-specified model for each position.</p>
                        </div>
                      </Row>
                      : '')}
                  </TabPane>
                  <TabPane tabId="transactions" id="Transactions">
                  
                       <AlertPortrait/>
                    {(
                      (activeTab === "transactions" && !portraitMode)?
                      <>
                        <h2>Transactions :</h2>
                        <Transactions
                          tabs={true}
                          hideAssetName={false}
                          hideCurrencyCols={false}
                          showTotalForeignCurrency={false}

                        />
                      </>
                      : '')}
                  </TabPane>
                  <TabPane tabId="budget" id="Budget">
                
                      <AlertPortrait/>
                    {((activeTab === "budget"  && !portraitMode) ? <Budget /> : '')}
                  </TabPane>
                  <TabPane tabId="charts" id="charts">
                
                     <AlertPortrait/>
                    {((activeTab === "charts"  && !portraitMode)? <><h2>Charts :</h2><DefaultChart/></> : '')}
                  </TabPane>
                  <TabPane tabId="table" id="table">
                
                      <AlertPortrait/>
                    {((activeTab === "table" && !portraitMode) ? <><h2>Table :</h2><Summary /></> : '')}
                  </TabPane>
                  <TabPane tabId="notes" id="Notes">
                    {(activeTab === "notes" ? <><h2>Notes :</h2><Notes /></> : '')}
                  </TabPane>


                </TabContent>


              </>
            )
          )}


        </Container>
      </div>
    </React.Fragment >
  )
  //}
}
  ;

export default MoonDanceDashboard;


/*
 <Row>




                      <CapitalCalledCard data={DATA} />


                    </Row>
                    <Row>
                      <DistributionNAVCard data={DATA} />



                    </Row>*/